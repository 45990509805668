<template>
  <div>
    <el-card class="card">
      <div class="top">
        <img src="@/assets/Ellipse_97.png" />
        <h3>{{ $t("financialCenter.myWallet") }}</h3>
        <h1>$0</h1>
      </div>

      <div class="main" style="position: relative;">
        <p>{{ $t("financialCenter.withdrawalAmount") }}</p>
        <el-input class="input" v-model="input" placeholder="" type="number">
          <template v-slot:prefix>
            <p>$</p>
          </template>
        </el-input>

        <p class="p1">
          The current change balance is $0.00,
          <span class="span1">which is fully withdrawn</span>
        </p>
        <div class="bottom">
          <div class="fl left">
            <p>{{ $t("financialCenter.received") }}</p>
          </div>
          <div class="fl right">
            <img src="@/assets/Frame_17.png" class="fl" />
            <el-select
              v-if="list.length"
              class="fl"
              v-model="test"
              :placeholder="$t('resident.pleaseSelect')"
            >
              <el-option
                v-for="card in list"
                :key="card.id"
                :value="card.id"
                :label="`${card.accountName}(${card.accountNo})`"
              ></el-option>
            </el-select>
            <p v-else class="fl ml p1">
              No bind bank card,
              <router-link to="/finances/bankCard">add now ></router-link>
            </p>
            <div class="clear"></div>
            <p class="p2">Arrive in two hours</p>
          </div>
          <div class="clear"></div>
        </div>
      </div>
      <div class="btn">
        <el-button class="button" disabled>{{
          $t("financialCenter.withdrawal")
        }}</el-button>
      </div>
      <!-- <div class="main">
        <div class="fl left">
          <p>Withdrawal amount</p>
          <p>Application time</p>
          <p>Payment date</p>
          <p>Withdrawal bank</p>
          <p>Withdrawal No</p>
        </div>

        <div class="fl right">
          <p>$599</p>
          <p>17:03:00, December 12, 2021</p>
          <p>17:03:00, December 12, 2021</p>
          <p>China Merchants Bank</p>
          <p>283773888474788559599595959959</p>
        </div>
      </div> -->
    </el-card>
  </div>
</template>

<script>
import { GetBankCardList } from "@/utils/api";
export default {
  data() {
    return {
      input: "",
      test: "",
      list: [],
    };
  },
  created() {
    this.getList();
  },
  methods: {
    getList() {
      GetBankCardList({}).then((res) => {
        this.list = res.data.rows;
      });
    },
  },
};
</script>

<style scoped>
.top {
  margin-left: 42%;
}
.top h3,
h1 {
  margin-bottom: 10px;
}
.top h1 {
  font-size: 50px;
  margin-left: -30px;
}
.top h3 {
  font-size: 13px;
  margin-left: 40px;
}
.top img {
  width: 131px;
  height: 131px;
}
.main {
  margin-left: 42%;
  margin-top: 45px;
}
.main p {
  font-size: 13px;
  padding-top: 10px;
  margin-left: -200px;
  font-weight: 500;
}
.main .input .el-input__inner {
  height: 88px;
  width: 633px;
  margin-top: 10px;
  margin-bottom: 25px;
  font-size: 35px;
  color: #000;
  font-weight: 700;
  border: 1px solid #f4f4f4;
  margin-left: -200px;
  padding-left: 10%;
  outline: none;
  background: #f4f4f4;
}
.input p {
  font-size: 40px;
  font-weight: 700;
  margin-top: 20px;
  margin-left: -320px;
  color: #000;
}
.p1 {
  color: #979797;
}
.span1 {
  color: #ff0a0a;
}
.bottom {
  margin-top: 45px;
}
.bottom p {
  color: #000;
  font-size: 15px;
}
.bottom .right {
  margin-left: 58px;
}
.bottom .right img {
  margin-top: 10px;
  width: 24px;
  height: 24px;
}
.right .p1 {
  font-size: 15px;
  margin-left: 5px;
  margin-top: 5px;
}
.right .p2 {
  margin-left: 35px;
  font-size: 11px;
  color: gray;
}
.btn {
  margin-left: 42%;
}
.button {
  margin-left: -100px;
  margin-top: 78px;
  width: 377px;
  height: 51px;
  /* color: #fff; */
  /* background-color: #ff0a0a; */
  border-radius: 10px;
  margin-bottom: 160px;
}
</style>